/*http://bourbon.io/docs/#buttons-variable */
#{$all-buttons}, .btn,
.button {

  appearance: none;
  background-color: $action-color;
  border:1px solid $action-color;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $heading-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  font-size:14px;
  line-height: 1;
  padding: 12px 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  transition:all .25s;
  @include media($medium-screen-up) {
    // font-size:16px;
    // padding: 15px $base-spacing;
  }

  &:visited{
    color: #fff;
  } 
  &:hover,
  &:focus {
    color: $c-bright-red;
    text-decoration: none;
    background: #fff;
    border-color:$c-bright-red;
  }



  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }

  &.btn-clear{
    background:rgba(0,0,0,.2);
    border:1px solid #a9a9ab;
    
    &:hover,
    &:focus{
      background:#000;
      color:#fff;
    }
  }

  &.btn-black{
    background:#000;
    color:#fff;
    border:1px solid #000;
    
    &:focus,
    &:hover{
      color:#000;
      background:#fff;
    }
  }

  &.btn-delivery{
    position: relative;
    margin-left:35px;
    @include media($medium-screen-up) {
      margin-left:60px;
    }
    &:before{
      position: absolute;
      left:-46px;
      top:0;
      content:'';
      vertical-align: middle;
      display: inline-block;
      background:url(../img/icons/icon-menu-delivery.png) right 0 no-repeat;
       width: 44px;
      height: 100%;
      background-size: contain;
    }
  }
}

/*doc
---
title: Buttons
name: buttons
category: Base CSS
---

```html_example_table                                                          

<button>Default</button>

<a href="#" class="btn">Link Button</a>                    

```
*/

