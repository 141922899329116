/*
*
* Tabs Styling. Switches to accordion for mobile
* Currently set up for Wordpress Flexible Content ACF fields 
**************************************************************/



.tabs-block{
	@include sidePad; 
	@include media($large-screen-up){
		border-bottom:1px solid $c-bright-red;
		margin-bottom: 30px;
	}
}


.tab-header{
	display: none;
	@include media($large-screen-up){
		display: flex;
		justify-content: space-between;
		align-items:flex-end;
	}

	li{
		text-align: center;
		color:#000;
		font-size:14px;
		font-weight: 700;
		text-transform: uppercase;
		padding:6px 5px;
		width:19.5%;
		border:1px solid #c8c8c8;
		border-bottom:0;
		border-radius:$base-border-radius $base-border-radius 0 0;
		background:#c8c8c8;
		margin-bottom: 5px;
		cursor: pointer;
		transition:all .25s;
		@include media($ex-large-screen-up){
			font-size:17px;
		}
	
		&:focus,
		&:hover,
		&[aria-selected="true"]
		{
			outline: none;
			background:#fff;
			border-color:$c-bright-red;
			padding-bottom:12px;/*12px + 6(5+1 margin)*/
			margin-bottom: -1px;
		}
	}
}

.tab-content{
	.section-inner{
		@include sidePad;
	}
}


.tab-title{
	border:1px solid $c-bright-red;
	padding:10px 20px;
	display:block;
	text-align: center;
	color:#000;
	font-size:14px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom:20px;

	@include media($medium-screen-up){
		display:none;
	}
	
}

.tab-box{
	
	@include media($medium-screen-up){
		display:none;
	}

	.inner{
		@include media($medium-screen-down){
			max-height: 0;
			overflow: hidden;
			transition:all .25s;
		}

		&[aria-hidden="false"]{
			max-height: 500px;
		}
	}

	&[aria-hidden="false"]{
		display: block;
		visibility: visible;
		opacity:1;
		max-height: 10000px;
		transition:all .75s;
	}
	

}



/**** menu page***/
.tab-overview{
	min-height: 480px;
	position: relative;
	transform:translateX(0);
	transition:all .75s;
	@include media($medium-screen-up){
		@supports(display:grid){
			display: grid;
			grid-template-columns: 34% 30% 34%;
			grid-column-gap: 1%;
			grid-row-gap:20px;;
			 min-width: 0;   /* NEW; needed for Firefox */
		}
	}

	&.hidden{
		transform:translateX(-200%);
		@include media($medium-screen-up){
			display: none;
			transform:translateX(0);
		}

	}

	.tab-overview-item{
		list-style: none;
		position: relative;
		width:320px;
		max-width: 100%;
		margin:0 auto 37px auto;
		cursor:pointer;
		@include media($medium-screen-up){
			margin-bottom: 37px;
			margin-left:0;
			margin-right: 0;
			width:34%;
			float:left;

			&:nth-child(2),
			&:nth-child(5){
				float:right; 
			}

			&:nth-child(3){
				clear:left;
			}

			&:nth-child(4){
				position: absolute;
				top:0;
				left:calc(34% + 5px);
				width:31%;
			}

			@supports(display:grid){
				width:auto;
				position: relative;
				margin:0;
				overflow: hidden;
				min-width: 0;   /* NEW; needed for Firefox */
				
				&:nth-child(4){
					position: relative;
					left:0;
					width:100%;
					grid-row-start:1;
					grid-row-end:3;
					grid-column-start:2;
					grid-column-end:3;

					.tab-overview-image img{ 
						position: relative;
						left:50%;
						margin-left:-158px; 
					}
				}

				&:nth-child(5){
					grid-column-start:3;
					grid-column-end:4;
				}

				img{
					height:100%;
					max-width: none;
				}
			}
		}

		.tab-overview-image img{ 
			display: block;
			margin:0 auto;
		}

		.tab-overview-content{
			position: absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			display: flex;
			justify-content: center;
			align-items:center;
		}

		.tab-overview-title{
			width:90%;
			max-width: 320px;
			display: inline-block;
			font-size:15px;
			color:#000;
			text-align: center;
			text-transform: uppercase;
			padding:12px;
			background:rgba(255,255,255,.8);
			@include media($medium-screen-up){
				font-size:20px;
				padding:15px;
			}

			&>span{
				display: block;
				padding:12px; 
				border:1px solid $c-bright-red;
				@include media($medium-screen-up){
					padding:20px;
				}
			}

		}
	}
}


	