ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.c-w1{
  ol{
    list-style: none;
    margin: 0.25em 0 1em 0em;

    li{
      position: relative;
      padding-left:25px;
      counter-increment: section; 
      line-height: 1.5em;
      margin-bottom: 8px;

      &:before{
        content: "("(counter(section)) ")"; 
        display: block;
        position: absolute;
        top:0;
        left:-3px; 
        color:$c-bright-red;
      }
    }

    ol{
      margin-bottom:0px;
    }
  }

  ul{
    list-style: none;
    margin: 0.25em 0 1em 0em;

    li{
      padding-left:15px;
      position: relative;
      line-height: 1.5em;
      margin-bottom: 8px;
      
      &:before{
        content:'';
        display: block;
        position: absolute;
        left:0;
        // top: 50%;
        // margin-top:-2.5px;
        top:.75em;
        width: 5px;
        height: 5px;
        background:$c-bright-red;
        border-radius: 50%;
        
      }
    }


    ul{
      margin-bottom:0;
    }
  }
}

/*doc
---
title: Lists
name: lists
category: Base CSS
---

These list styles are only applied in the content area. They are targeted
using the .c-w1 class.

<div class='c-w1'>
<ul>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ul>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ul>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ul>

<ol>
  <li>List Item</li>
  <li>List Item</li>
  <li>List Item
      <ol>
        <li>List Item</li>
        <li>List Item</li>
        <li>List Item</li>
      </ol>
  </li>
  <li>List Item</li>
  <li>List Item</li>
</ol>
</div>

*/