/*
*
* Accordion Styling. 
* Currently set up for Wordpress Flexible Content ACF fields 
**************************************************************/
.accordion{

	.accordion-item{
		border:1px solid $c-bright-red;
		margin-bottom: 20px;

		&.active{
			border-color:#000;
		}
	}


	.accordion-title{
		position: relative;
		font-family: $heading-font-family;
		font-size:19px;
		font-weight: 400;
		padding:18px 25px;
		cursor:pointer;
		margin:0;
		background:$c-bright-red;
		color:#fff;
		@include media($medium-screen-up){
			font-size:30px;
		}

		&:after{
			position: absolute;
			top:20px;
			right:20px;
			font-family: "FontAwesome";
			content:'\f063';
			display: block;
			width:20px;
			height:20px;
			line-height: 20px;
			font-size: 15px;
		    text-align: center;
			background:#fff;
			color:$c-bright-red;
			border-radius:50%;
			transition:all .25s;
			@include media($medium-screen-up){
				width:33px;
				height:33px;
				line-height: 33px;
				font-size:20px;
			} 
		}

		
		&:focus{
			outline: none;
			border-color:#000;
			color:#000;
			background:#fff;
			&:after{
				color:#fff;
				background:$c-bright-red;
			}
		}
	}


	.active{

		.accordion-title{
			border-color:#000;
			color:#000;
			background:#fff;

			&:after{
				transform:rotate(180deg) !important;
				color:#fff;
				background:$c-bright-red;
			}
		}
	}

	.accordion-content{
		overflow: hidden;
		max-height: 0;
		padding:0px 25px;
		transition:all .25s;
		position: relative;
	}

	.accordion-content[aria-hidden='false']{
		padding:20px 25px;
		max-height: 1000px;
		transition:all .25s;
	}
}
		