// // Returns rem and px
// // Note: Assumes html font size 62.5%
// @mixin font-size($sizeValue: 1.6) {
//   font-size: ($sizeValue * 10) + px;
//   font-size: $sizeValue + rem;
// }

@mixin elementInvisible(){
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin border(){
	border:1px solid #ccc;
} 


@mixin fillScreen(){
	margin-left:-$global-edge-padding;
	margin-right:-$global-edge-padding;
} 

@mixin sidePad(){
	padding-left:$global-edge-padding;
	padding-right:$global-edge-padding;
} 

@mixin buttonClear(){
	background:transparent;
	border:1px solid #000;
	color:$c-bright-red;

	&:hover{
		background:#000;
		color:#fff;
	}
}

@mixin titleLine(){
	&:after{
		content:'';
		display: block;
		width:70px;
		padding-top:5px;
		border-bottom:1px solid #000;
		margin-bottom: 0.5em;
	}
}